<template>
    <div class="withdraw">
        <Top name="提现" back email></Top>
        <div class="withdraw_w">
           <div class="withdraw_w_t">
                <h3>¥{{balance}}</h3>
                <van-form class="form_w">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.type"
                        label=""
                        placeholder="请选择提现方式"
                        @click="form.showPicker = true"
                    />
                    <van-popup v-model="form.showPicker" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="form.columns"
                            @confirm="onConfirm"
                            @cancel="form.showPicker = false"
                        />
                    </van-popup>
                    <van-field v-model="form.zfbaccount" v-show="form.pay_type == 'alipay'" auto-complete="new-password" label="" placeholder="请输入支付宝账号" />
                    <van-field v-model="form.zfb_name" v-show="form.pay_type == 'alipay'" auto-complete="new-password" label="" placeholder="请输入支付宝账户持有人姓名" />
                    <van-field v-model="form.wxaccount" v-show="form.pay_type == 'wxpay'" auto-complete="new-password" label="" placeholder="请输入微信账号" />
                    <van-field v-model="form.wx_name" v-show="form.pay_type == 'wxpay'" auto-complete="new-password" label="" placeholder="请输入微信号对应昵称" />
                    <van-field v-model="form.money" label="" class="money_inp1" auto-complete="new-password" placeholder="请输入提现金额" @input="handlerInp" @blur="handlerBlur" />
                    <van-field v-model="form.password" type="password" auto-complete="new-password" label="" placeholder="请输入支付密码" />
                </van-form>
                <router-link :to="{ name: 'Passwordset', params: { active: 1}}" class="orange">忘记密码</router-link>
           </div>
           <div class="withdraw_line"></div>
           <div class="withdraw_w_c">
               <div class="withdraw_item">
                   <span>提现手续费怎么收取？</span>
                   <p v-if="servicePer != 0">答：每笔提现收取{{servicePer}}%作为手续费，最低{{serviceMin}}元最高{{serviceMax}}元；</p>
                   <p v-else>答：提现不收取手续费。</p>
               </div>
               <div class="withdraw_item">
                   <span>提现限额为多少？</span>
                   <p>答：单笔提现最低10元，最高5万，24小时内提现一次。</p>
               </div>
               <div class="withdraw_item">
                   <span>提现到账时间？</span>
                   <p>答：1~3个工作日。</p>
               </div>
           </div>
           <div class="withdraw_line"></div>
           <div class="withdraw_btn">
               <van-button round type="info" size="large" :disabled="disabled" color="linear-gradient(to right, #FF704D, #F73727)" @click="onSubmit">
                   申请提现
               </van-button>
           </div>
        </div>
    </div>
</template>
<script>
 import Top from '@/components/top'
 import {debounce} from "@/assets/js/utils.js";

export default {
    name: 'Withdraw',
    components: {
        Top,
    },
    data() {
        return {
            balance: '',
            form: {
                type: '',
                columns: [
                    {
                        text: '支付宝',
                        id: 'alipay'
                    }
                    // ,{
                    //     text: '微信',
                    //     id: 'wxpay'
                    // }
                ],
                showPicker: false,
                money: '',
                password: '',
                pay_type: '',
                zfbaccount: '',
                zfb_name: '',
                wxaccount: '',
                wx_name: '',
            },
            plusShow: false,
            minusShow: false,
            isNumberTrue: false,
            // click: '',
            // debounce,
            servicePer: 0,
            serviceMin: 0,
            serviceMax: 0,
            disabled: false
        }
    },
    created() {
        this.form.money = this.form.money.split('.')[0];
        //获取账户余额
        this.getBalance();
        //获取交易规则信息
        this.getRule();
        //防抖动
        // this.click = this.debounce(this.withdraw, 2000);
    },
    methods: {
        //获取交易规则信息
        getRule() {
            this.$api.sale.getRule().then(res => {
                if (res.code == 200) {
                    this.servicePer = res.data.servicePer;
                    this.serviceMin = res.data.serviceMin;
                    this.serviceMax = res.data.serviceMax;
                }
            });
        },
        //弹窗确认
        onConfirm(val, index) {
            this.form.type = val.text;
            this.form.pay_type = val.id;
            this.form.showPicker = false;
        },
        //重置
        resetForm() {
            this.form.type = '';
            this.form.money = '';
            this.form.password = '';
            this.form.pay_type = '';
            this.form.zfbaccount = '';
            this.form.zfb_name = '';
            this.form.wxaccount = '';
            this.form.wx_name = '';
        },
        //输入金额改变
        handlerInp(val) {
            this.form.money = val;
            this.clearNoNum(this.form);
        },
        //输入金额失去焦点
        handlerBlur() {
            //console.log(Number(this.form.money));
            //this.isNumberTrue = false;
            this.form.money = Number(this.form.money);
            //对于输入金额的判断
            this.moneyCompare();
        },
        //提现金额判断
        moneyCompare() {
            this.isNumberTrue = false;
            if (parseFloat(this.form.money) >= 10) {
                if (parseFloat(this.form.money) <= 100000) {
                    if (parseFloat(this.form.money) <= parseFloat(this.balance)) {
                        this.isNumberTrue = true;
                    }else{
                        this.$toast({
                            message: '余额不足',
                            duration: 1500
                        });
                    }
                }else{
                    this.$toast({
                        message: '单笔最高100000元',
                        duration: 1500
                    });
                }
            }else{
                this.$toast({
                    message: '单笔最低10元',
                    duration: 1500
                });
            }
            return this.isNumberTrue;
        },
        //只能输入整数和小数，保留两位小数
        clearNoNum(obj) {
            if (!/^(\d+|\d*\.(\d{2}|\d{1}))$/.test(Number(obj.money))) {
                obj.money = obj.money.replace(/[^\d.]/g, "");
                if (obj.money.indexOf('.') > -1 && obj.money.split('.').length == 2) {
                    if (obj.money.split('.')[1].length > 2) {
                        obj.money = obj.money.substring(0, obj.money.length - 1);
                    }
                }else{
                    obj.money = obj.money.substring(0, obj.money.length - 1);
                }
            }
        },
        //申请提现
        onSubmit() {
            //console.log(this.form);
            //判断是否点击时间超过两秒
            if (this.disabled) {
                this.$toast({
                    duration: 1500,
                    message: '操作过于频繁，请稍后再试'
                });
                return false;
            }

            if (this.form.pay_type != '') {
                //判断提现金额是否合格
                let returnVal = this.moneyCompare();
                if (!returnVal) {
                    return false;
                }
                if (this.form.money != '' && this.form.password != '' && ((this.form.zfbaccount != '' && this.form.zfb_name != '') || (this.form.wxaccount != '' && this.form.wx_name != ''))) {
                    if (this.isNumberTrue) {
                        //发起提现请求
                        this.withdraw();
                        //console.log('发起请求');
                        this.disabled = true;
                        setTimeout(() => {
                            this.disabled = false;
                        }, 2000);
                        // this.click();
                    }
                }else{
                    this.$toast({
                        message: '请先填完输入框',
                        duration: 1500
                    });
                }
            }else{
                this.$toast({
                    message: '请先填完输入框',
                    duration: 1500
                });
            }
        },
        //获取账户余额
        getBalance() {
            let username = localStorage.getItem('username');
            this.$api.account.getBalance({username:username}).then((res)=>{
                if (res.code == 200) {
                    this.balance = res.data.accountBalance;
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //发起提现请求
        withdraw() {
            // this.clearHttpRequestingList();
            let params = this.form.pay_type == 'alipay' ? {
                pay_type: this.form.pay_type,
                pay_account: this.form.zfbaccount,
                pay_name: this.form.zfb_name,
                money: this.form.money,
                pay_password: this.form.password,
            } : {
                pay_type: this.form.pay_type,
                pay_account: this.form.wxaccount,
                pay_name: this.form.wx_name,
                money: this.form.money,
                pay_password: this.form.password,
            };
            this.$api.account.withdraw(params).then(res => {
                if (res.code == 200) {
                    //请求后给提示
                    this.$dialog.alert({
                        title: '',
                        message: '提现申请已提交，系统将会在24小时内确认审核结果，钱包余额扣除，如信息审核失败余额将退回，审核结果将以站内信形式通知',
                    });
                    //重置
                    this.resetForm();
                }
                //获取账户余额
                this.getBalance();
            }).catch(err => {
                console.log(err);
            });
        }
    },
    // beforeDestroy() {
    //     this.debounce=null;//清除闭包
    //     // console.log('销毁了')
    // }
}
</script>
<style scoped>
.withdraw_w_t{
    padding: .6875rem 0 .625rem;
    overflow: hidden;
    border-bottom: 1px solid #dedede;
}
.withdraw_w_t h3{
    padding: 0 1.1875rem;
    color: #ff4e00;
    font-size: 1rem;
    line-height: 3.46875rem;
    border-bottom: 1px solid #dedede;
    position: relative;
}
.withdraw_w_t h3::before{
    content: '余额：';
    position: absolute;
    left: 1.1875rem;
    top: 50%;
    transform: translate(0, -50%);
    color: #707070;
}
.form_w{
    margin: .8125rem 1.1875rem .3125rem;
}
.form_w .van-cell{
    background-color: #efefef;
    margin-bottom: .78125rem;
    border-radius: 5px;
}
.withdraw_w_t a{
    font-size: .71875rem;
    line-height: 1.8em;
    float: right;
    margin-right: 1.1875rem;
    color: #227eeb;
    text-decoration: underline;
}
.withdraw_line{
    height: .46875rem;
    background-color: #ececec;
    border-bottom: 1px solid #dedede;
}
.withdraw_w_c{
    padding: .9375rem 1.1875rem;
    /* border-top: 1px solid #dedede; */
    border-bottom: 1px solid #dedede;
    text-align: left;
    font-size: .75rem;
    line-height: 1.8em;
    color: #313131;
}
.withdraw_w_c .withdraw_item{
    margin-bottom: .9375rem;
}
.withdraw_w_c .withdraw_item:last-child{
    margin-bottom: 0;
}
.withdraw_w_c .withdraw_item p{
    color: #909090;
}
.withdraw_btn{
    margin: 10% 10% 0;
    padding: 10% 0;
}
.withdraw_btn button{
    font-size: 1.2rem;
}

</style>
<style>
.form_w .van-field__control{
    text-align: center;
    font-size: .8125rem;
}
.money_inp1 .van-field__body{
    position: relative;
}
.money_inp1 .van-field__body::after{
    content: '元';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}
.money_inp{
    background-color: #efefef;
    margin-bottom: .78125rem;
    border-radius: 5px;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}
.money_inp input{
    width: 100%;
    text-align: center;
    font-size: .8125rem;
    background: none;
}
.money_inp input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #bac0c5;
}
.money_inp input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: #bac0c5;
}
.money_inp input::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: #bac0c5;
}
.money_inp input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #bac0c5;
}
.money_inp input::-ms-input-placeholder { /* Microsoft Edge */
   color: #bac0c5;
}
.money_inp::after{
    content: '元';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
}
</style>
