
export function  debounce(fn,duration=100){
    let timer=null
    return (...args)=>{
        // console.log(321)
        clearTimeout(timer)
        timer=setTimeout(()=>{
            fn(...args);
        },duration)
 
    }
}
export function throttle(fn,duration=100){
    let target=true;
    return (...arg)=>{
        if(!target){
            return false;
        }
        target =false;
        setTimeout(()=>{
            fn(...arg);
            target=true
        },duration)
    }
}